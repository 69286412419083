<script setup lang="ts">
import { tv, type VariantProps } from 'tailwind-variants'

type NotificationVariants = VariantProps<typeof notificationVariants>

interface Props {
  is?: keyof HTMLElementTagNameMap
  variant?: NotificationVariants['variant']
  class?: any
}

const props = withDefaults(defineProps<Props>(), {
  is: 'div',
})

const notificationVariants = tv({
  base: 'relative block bg-blue/15 p-4 text-center',
  variants: {
    variant: {
      success: 'bg-green/15',
      error: 'bg-red/15',
    },
  },
})
</script>

<template>
  <component :is="props.is" :class="notificationVariants({ variant, class: props.class })" role="alert">
    <slot />
    <!-- TODO: Add close button -->
    <!-- <button v-if="" title="Close" aria-label="Close" class="absolute right-4 top-4 flex rounded">
      <Icon name="heroicons:x-mark" class="size-6" />
    </button> -->
  </component>
</template>

<style scoped>
:deep(:focus-visible) {
  @apply outline-white/30;
}
</style>
